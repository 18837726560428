@import url(https://fonts.googleapis.com/css?family=Roboto|Noto+Sans+SC&display=swap);
.app-root {
	display: flex;
	flex-flow: column;
	height: 100vh;
	width: 100vw;
	background-color: #fff;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-area {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-flow: column;
	flex-direction: column;
	padding-top: 45px;
	padding-bottom: 74px;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-navs {
	position: fixed;
	z-index: 999;
	display: block;
	width: 100vw;
	bottom: 0;
	background-color: #ffd54f;
	border-top: 1px solid #d3d3d3;
	padding-top: 5px;
	padding-bottom: 15px;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

#navbar-row {
	width: 100%;
	margin-right: 0px;
	margin-left: 0px;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.navbar-option {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #000;
	text-decoration: none;
	font-weight: 700;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.inactive {
	color: #808080;
	font-weight: 400;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.navbar-option:hover {
	color: #000;
	text-decoration: none;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

i.navbar-option-icon {
	font-size: 35px;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.navbar-option-text {
	font-size: 12px;
	font-weight: inherit;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.home-root {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	flex-direction: column;
	padding-left: 10px;
	padding-right: 10px;
}

.home-app-logo {
	max-width: 50%;
	max-height: 300px;
	margin-bottom: 20px;
}

.home-app-addtohome {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 50px;
	border: 1px solid #808080;
	border-radius: 10px;
	margin-bottom: 10px;
	padding: 5px 2px 5px 2px;
	text-decoration: none;
	color: #000;
}

.home-app-addtohome:hover {
	text-decoration: none;
	color: #000;
}

.home-app-addtohome-icon {
	max-height: 100%;
	max-width: 100%;
	margin-right: 15px;
}

.list-items {
	flex: 1 1 auto;
	cursor: pointer;
}

.list-item {
	padding-left: 5px;
	padding-right: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.list-item-code {
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
}

.list-item-title {
	border-bottom: 1px solid #d3d3d3;
	font-size: 11px;
}

.list-search-root {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px 5px 5px 5px;
	border-bottom: 1px solid #d3d3d3;
}

.list-search-code {
	flex: 0 1 auto;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
}

.list-search-icon {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #808080;
}

.list-search-area {
	flex: 15 1 auto;
	display: flex;
}

.list-search-clear {
	flex: 2 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #808080;
	cursor: pointer;
}

.list-search-input {
	width: 100%;
	border: none;
	padding: 0px 5px 0px 5px;
	font-size: 16px;
	text-transform: uppercase;
}

.list-search-input:focus {
	outline: none;
}

.list-search-term {
	width: 100%;
	padding-right: 3px;
}

.list-search-term-select {
	width: 100%;
	padding: 2px 3px 2px 3px;
	border: none;
	outline: none;
	font-size: 16px;
	background: #fff;
}

@font-face {
	font-family: ImpactTTF;
	src: url(/static/media/impact.8fc622c3.ttf) format('truetype');
}

.app-title {
	position: fixed;
	z-index: 999;
	display: block;
	width: 100vw;
	background-color: #ffd54f;
	font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', ImpactTTF, sans-serif;
	text-align: center;
	font-size: 30px;
	border-bottom: 1px solid #d3d3d3;
	display: flex;
	flex-direction: row;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-title-left {
	width: 10%;
	flex: 0 1 auto;
	display: flex;
	align-items: center;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-title-center {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-title-right {
	width: 10%;
	flex: 0 1 auto;
	display: flex;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

i.app-title-icon {
	font-size: 35px;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.details-root {
	width: 100%;
	min-height: 100vh;
	padding: 57px 15px 30px 15px;
	background: #fff;
}

.details-code {
	font-weight: 700;
	font-size: 30px;
}

.details-title {
	color: #000;
	font-size: 16px;
	margin-bottom: 16px;
}

.details-part-title {
	color: #000;
	font-size: 16px;
	margin-bottom: 5px;
	font-weight: 600;
}

.details-part-body {
	font-size: 14px;
	margin-bottom: 16px;
	color: #212529;
}

.details-sections-root {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 10px;
	padding: 5px;
	font-size: 14px;
}

.details-sections-row {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.details-sections-list {
	display: flex;
	flex-direction: column;
	width: 100%;
	border: 1px solid #d3d3d3;
	padding: 5px;
}

.details-sections-col-l {
	display: flex;
	align-items: center;
	width: 60%;
}

.details-sections-col-m {
	display: flex;
	align-items: center;
	width: 30%;
}

.details-sections-col-rest {
	display: flex;
	align-items: center;
	width: 40%;
}

.details-sections-col-r {
	display: flex;
	justify-content: center;
	width: 10%;
}

i.details-sections-star {
	font-size: 18px;
}

.details-sections-timeslot-root {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.details-sections-timeslot-root:not(:first-child) {
	border-top: 1px solid #d3d3d3;
	margin-top: 10px;
	padding-top: 5px;
}

div.carousel-inner {
	height: 100%;
	width: 100%;
	text-align: center;
}

a.carousel-control-prev {
	height: 80px;
	width: 10%;
	top: auto;
	bottom: 50%;
	background-color: rgba(0, 0, 0, 0.3);
}

a.carousel-control-next {
	height: 80px;
	width: 10%;
	top: auto;
	bottom: 50%;
	background-color: rgba(0, 0, 0, 0.3);
}

ol.carousel-indicators {
	background-color: rgba(0, 0, 0, 0.3);
}

/* Global CSS file */

* {
	padding: 0px;
	margin: 0px;
}

body {
	background-color: #000;
	font-family: 'Roboto', 'Noto Sans SC', sans-serif;
}

