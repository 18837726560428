/* Global CSS file */
@import url('https://fonts.googleapis.com/css?family=Roboto|Noto+Sans+SC&display=swap');

* {
	padding: 0px;
	margin: 0px;
}

body {
	background-color: #000;
	font-family: 'Roboto', 'Noto Sans SC', sans-serif;
}
