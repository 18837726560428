@font-face {
	font-family: ImpactTTF;
	src: url('impact.ttf') format('truetype');
}

.app-title {
	position: fixed;
	z-index: 999;
	display: block;
	width: 100vw;
	background-color: #ffd54f;
	font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', ImpactTTF, sans-serif;
	text-align: center;
	font-size: 30px;
	border-bottom: 1px solid #d3d3d3;
	display: flex;
	flex-direction: row;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-title-left {
	width: 10%;
	flex: 0 1 auto;
	display: flex;
	align-items: center;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-title-center {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-title-right {
	width: 10%;
	flex: 0 1 auto;
	display: flex;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

i.app-title-icon {
	font-size: 35px;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}
