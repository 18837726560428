.app-root {
	display: flex;
	flex-flow: column;
	height: 100vh;
	width: 100vw;
	background-color: #fff;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-area {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-flow: column;
	flex-direction: column;
	padding-top: 45px;
	padding-bottom: 74px;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}

.app-navs {
	position: fixed;
	z-index: 999;
	display: block;
	width: 100vw;
	bottom: 0;
	background-color: #ffd54f;
	border-top: 1px solid #d3d3d3;
	padding-top: 5px;
	padding-bottom: 15px;
	/* Prevent mobile fixed element flicker */
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-ms-transform: translateZ(0);
}
