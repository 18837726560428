.details-root {
	width: 100%;
	min-height: 100vh;
	padding: 57px 15px 30px 15px;
	background: #fff;
}

.details-code {
	font-weight: 700;
	font-size: 30px;
}

.details-title {
	color: #000;
	font-size: 16px;
	margin-bottom: 16px;
}

.details-part-title {
	color: #000;
	font-size: 16px;
	margin-bottom: 5px;
	font-weight: 600;
}

.details-part-body {
	font-size: 14px;
	margin-bottom: 16px;
	color: #212529;
}
