.home-root {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
	flex-direction: column;
	padding-left: 10px;
	padding-right: 10px;
}

.home-app-logo {
	max-width: 50%;
	max-height: 300px;
	margin-bottom: 20px;
}

.home-app-addtohome {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: 160px;
	height: 50px;
	border: 1px solid #808080;
	border-radius: 10px;
	margin-bottom: 10px;
	padding: 5px 2px 5px 2px;
	text-decoration: none;
	color: #000;
}

.home-app-addtohome:hover {
	text-decoration: none;
	color: #000;
}

.home-app-addtohome-icon {
	max-height: 100%;
	max-width: 100%;
	margin-right: 15px;
}
