.list-search-root {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 10px 5px 5px 5px;
	border-bottom: 1px solid #d3d3d3;
}

.list-search-code {
	flex: 0 1 auto;
	display: flex;
	flex-direction: row;
	margin-bottom: 10px;
}

.list-search-icon {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #808080;
}

.list-search-area {
	flex: 15 1 auto;
	display: flex;
}

.list-search-clear {
	flex: 2 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #808080;
	cursor: pointer;
}

.list-search-input {
	width: 100%;
	border: none;
	padding: 0px 5px 0px 5px;
	font-size: 16px;
	text-transform: uppercase;
}

.list-search-input:focus {
	outline: none;
}

.list-search-term {
	width: 100%;
	padding-right: 3px;
}

.list-search-term-select {
	width: 100%;
	padding: 2px 3px 2px 3px;
	border: none;
	outline: none;
	font-size: 16px;
	background: #fff;
}
